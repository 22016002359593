// custom typefaces
import "typeface-lato"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "./src/css/@wordpress/block-library/build-style/style.css"
import "./src/css/@wordpress/block-library/build-style/theme.css"

// normalize CSS across browsers
//import "./src/css/normalize.css"
import "./src/css/bootstrap.css"
import "./src/css/gutenberg-default.css"
import "./src/css/theme.css"
import "./src/css/blocks.css"

// custom CSS styles
import "./src/css/combo-styles.css"
import "./src/css/custom-blocks.css"
import "./src/css/style.css"
import "./src/css/customizer.css"

const addScript = url => {
  const script = document.createElement("script")
  script.src = url
  script.async = true
  document.body.appendChild(script)
}

export const onRouteUpdate = () => {
  // Add Popper.js (required by Bootstrap)
  addScript(
    "https://cdnjs.cloudflare.com/ajax/libs/popper.js/2.10.2/umd/popper.min.js"
  )

  // Add Bootstrap 5 script
  addScript(
    "https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.min.js"
  )
  addScript(
    "https://centerforinquiry.org/wp-content/themes/cfi-theme/js/clicky-menus.js"
  )
  addScript(
    "https://centerforinquiry.org/wp-content/themes/cfi-theme/js/navigation.js"
  )
  addScript(
    "https://centerforinquiry.org/wp-content/themes/cfi-theme/js/translations-welcome-2.js"
  )
  addScript(
    "https://centerforinquiry.org/js/woocommerce/donations-memberships.js"
  )
  addScript("https://centerforinquiry.org/js/everyactionmembership.js")
}
